 @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500&display=swap");

 * {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
 }

 body {
   height: 100svh;
   text-rendering: optimizeLegibility;
   -webkit-font-smoothing: antialiased;
   font-family: "Manrope";
   line-height: 1;
   color: #000000;
   font-size: 1.042vw;
   font-size: 0.847vw;
 }

 #root {
   height: 100%;
 }

 .corner-box-div {
   border: 4px solid black;
 }

 .T-shaped-thing {
   width: 4px;
   background-color: black;
   position: absolute;
   height: 40px;
   top: 0;
   left: 50%;
 }

 .T-shaped-thing-head {
   height: 4px;
   background-color: black;
   position: absolute;
   bottom: 0;
   transform: translateX(-50%);
 }

 .T-shaped-thing-side {
   position: absolute;
   width: 40px;
   height: 4px;
   background-color: black;
   top: 50%;
   left: 0;
 }

 .T-shaped-thing-side-head {
   position: absolute;
   width: 4px;
   height: 95px;
   right: 0;
   background-color: black;
   top: 50%;
   transform: translateY(-50%);
 }

 .animated-text-container {
   padding: 0 44px;
   width: 100%;
   display: flex;
   font-style: normal;
   position: absolute;
   left: 0;
   top: 50%;
   cursor: pointer;
   -webkit-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
   transform: translateY(-50%);
   -webkit-tap-highlight-color: transparent;
   tap-highlight-color: transparent;
 }

 .mask {
   -webkit-mask-image: url("../public/LogoMask.svg");
   mask-size: contain;
   -webkit-mask-size: contain;
   mask-image: url("../public/LogoMask.svg");
   mask-repeat: no-repeat;
   -webkit-mask-repeat: no-repeat;
 }

@media screen and (max-width: 900px) {
   body {
     font-size: 1.78vw;
   }
   .corner-box-div {
     border: 2px solid black;
   }
   .T-shaped-thing {
     width: 2px;
     height: 11.5px;
   }
   .T-shaped-thing-head {
     height: 2px;
   }
   .T-shaped-thing-side {
     width: 11.5px;
     height: 2px;
   }

   .T-shaped-thing-side-head {
     width: 2px;
     height: 95px;
   }
 }
