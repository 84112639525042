 @font-face {
   font-family: "FFF Acid Grotesk";
   src:
     url("./font/FFF-AcidGrotesk-Regular-TRIAL.woff2") format("woff2"),
     url("./font/FFF-AcidGrotesk-Regular-TRIAL.woff") format("woff");
   font-weight: normal;
   font-style: normal;
   font-display: swap;
 }

 @font-face {
   font-family: "FFF Acid Grotesk Normal";
   src:
     url("./font/FFF-AcidGrotesk-Normal-TRIAL.woff2") format("woff2"),
     url("./font/FFF-AcidGrotesk-Normal-TRIAL.woff") format("woff");
   font-weight: normal;
   font-style: normal;
   font-display: swap;
 }
 body {
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
     sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }
